import { RowItem, Gap } from "./../../../../components";
import React, { useState } from "react";
import classNames from "classnames";
import { Col, Row, Button, Collapse } from "reactstrap";
import FormRateLimit from "./FormRateLimit";
import { useSetRateLimitMutation } from "../../../device/service/deviceApi";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { Can, REBOOT_DEVICE } from "./../../../../config";

const DeviceRemoteConfiguration = ({
  device,
  refetch,
  onRebootDevice,
  deviceStatus,
  onToggleDeviceStatus,
  onUpdateFirmware,
  firmwareInfo,
  updateAvailable,
  firmwareVersion,
}) => {
  const { rateLimitDonwloadInKb, rateLimitUploadInKb, hasTunnelDetails } = device;  

  const [showRateLimitConfig, setShowRateLimitConfig] = useState(true);
  const [setRateLimit] = useSetRateLimitMutation();

  const onSubmitRateLimit = ({
    rateLimitUploadInKb,
    rateLimitDownloadInKb,
  }) => {
    Swal.fire({
      icon: "question",
      title: `Are you sure to set rate limit device?`,
      text: `Device: ${device?.sn}, Type: ${device?.type}.`,
      showLoaderOnConfirm: true,
      showCancelButton: true,
      confirmButtonText: "Yes",
      reverseButtons: true,
      preConfirm: async () => {
        try {
          await setRateLimit({
            deviceId: device?.id,
            rateLimitUploadInKb,
            rateLimitDownloadInKb,
          }).unwrap();
          toast.success("Set rate limit executed.");
          await refetch();
          return true;
        } catch {
          return false;
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    });
  };

  return (
    <Row>
      <Col className="col-6 px-5 pb-5 border-right">
        <h2 className="text-dark ml--3">Device Remote Configuration</h2>
        <Gap height={15} />
        <Can I="do" this={REBOOT_DEVICE}>
          <RowItem
            stripped
            label={"Reboot Device"}
            value={
              <Button
                className="btn btn-danger px-5 py-1 ml-auto"
                onClick={onRebootDevice}
              >
                Reboot Device
              </Button>
            }
          />
        </Can>
        <Can I="do" this={REBOOT_DEVICE}>
          <RowItem
            label={"Enable/Disable Tunnel"}
            value={
              !hasTunnelDetails ? 
              <span className="text-muted">No tunnel configured</span>
              :
              <Button
                className={classNames("btn px-5 py-1 ml-auto", {
                  "btn-danger": deviceStatus,
                  "btn-success": !deviceStatus,
                })}
                onClick={onToggleDeviceStatus}
              >
                {deviceStatus ? "Disable Tunnel" : "Enable Tunnel"}
              </Button>
            }
          />
        </Can>
        <RowItem
          label="Rate Limit Configuration"
          value={
            <span>
              <button
                type="button"
                className="btn btn-sm btn-link bg-transparent ml-2"
                onClick={() => setShowRateLimitConfig(!showRateLimitConfig)}
              >
                <i
                  className={classNames("fa text-default", {
                    "fa-chevron-down": !showRateLimitConfig,
                    "fa-chevron-up": showRateLimitConfig,
                  })}
                ></i>
              </button>
            </span>
          }
        />
        <Collapse isOpen={showRateLimitConfig}>
          <FormRateLimit
            initialValues={{ rateLimitDonwloadInKb, rateLimitUploadInKb }}
            onSubmit={onSubmitRateLimit}
          />
        </Collapse>
      </Col>
      <Col className="col-6 px-5 pb-5 border-left">
        <Gap height={52} />
        <div style={{ backgroundColor: "#F7FAFC", padding: 12 }}>
          <RowItem
            stripped
            label={"Firmware"}
            value={
              updateAvailable ? (
                <Button
                  className="btn btn-success px-5 py-1 ml-auto"
                  onClick={onUpdateFirmware}
                >
                  Update Now
                </Button>
              ) : null
            }
          />
          <Row>
            <Col>
              <span className="text-sm">
                Current Version: {firmwareVersion || "-"}
              </span>
            </Col>
          </Row>
          {updateAvailable && firmwareInfo && (
            <Row>
              <Col>
                <span className="text-sm">
                  Available Update Version: {firmwareInfo.version}
                </span>
              </Col>
            </Row>
          )}
        </div>
      </Col>
    </Row>
  );
};

export default DeviceRemoteConfiguration;
