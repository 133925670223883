import { PageContent } from "components";
import React, { useMemo } from "react";
import PageToolbar from "../component/PageToolbar";
import { TabPanel, TabView } from "primereact/tabview";
import { Card, CardBody } from "reactstrap";
import "./styles.css";
import { useParams } from "react-router";
import { history } from "utils";

const firewallDummy = [
  { label: "Floating", id: 0 },
  { label: "LAN", id: 1 },
  { label: "Loopback", id: 2 },
  { label: "VL2", id: 3 },
  { label: "WAN1", id: 4 },
];

const routeMap = firewallDummy.reduce((map, item) => {
  map[item.id] = item.label;
  return map;
}, {});

const FirewallRuleList = () => {
  const { sn, firewallId } = useParams();

  const activeIndex = useMemo(() => {
    if (!firewallId) return 0;
    const id = parseInt(firewallId, 10); // Convert firewallId to a number
    return firewallDummy.some((tab) => tab.id === id) ? id : 0; // Default to 0 if not found
  }, [firewallId]);

  const onTabChange = (index) => {
    if (index in routeMap) {
      history.replace(`/admin/sdwan/device/${sn}/firewall/rules/${index}`);
    }
  };

  const title = useMemo(() => {
    if (!sn) return "Firewall Rules";
    return `${sn}'s Firewall Rules`;
  }, [sn]);

  return (
    <PageContent style={{ marginTop: "-14rem" }}>
      <PageToolbar title={title} />
      <Card className="tab-gateways">
        <CardBody className="p-3">
          <TabView
            className="tab-gateway"
            activeIndex={activeIndex}
            onTabChange={(e) => onTabChange(e.index)}
          >
            {firewallDummy.map((tab) => (
              <TabPanel key={tab.id} header={tab.label}>
                <div>Content for {tab.label}</div>
              </TabPanel>
            ))}
          </TabView>
        </CardBody>
      </Card>
    </PageContent>
  );
};

export default FirewallRuleList;
