// FOR DEV
// export const API_BASE_URL = "https://iot-device-management-cloud.tritronik.com";
// export const API_BASE_URL_USAGE = "https://iot-device-usage-cloud.tritronik.com";
// export const API_URL_ALERT = "https://iot-device-alertmgmt-cloud.tritronik.com";
// export const GA_ID = "UA-189456706-1";
// export const APP_URL = "https://lintasarta-dm.tritronik.com/";
// export const APP_BRAND_LOGO = "https://lintasarta-dm.tritronik.com/assets/img/lintas-arta-logo.png";
// export const APP_CLIENT_LOGO = "https://lintasarta-dm.tritronik.com/assets/img/lintas-arta-logo.png";

// FOR PROD
export const API_BASE_URL = "https://iot-device-management.tritronik.com";
export const API_BASE_URL_USAGE = "https://iot-device-usage.tritronik.com";
export const API_URL_ALERT = "https://iot-device-alertmgmt.tritronik.com";
export const GA_ID = "UA-189456706-2";
export const APP_URL = "https://iotcc.tritronik.com/";
export const APP_BRAND_LOGO = "https://iotcc.tritronik.com/assets/img/lintas-arta-logo.png";
export const APP_CLIENT_LOGO = "https://iotcc.tritronik.com/assets/img/lintas-arta-logo.png";


export const ZEROTIER_API_TOKEN = "8dwh0b8hcekaf6hujztcbiis";
export const ZEROTIER_NODE_ID = "2d7d6f8c3a";

// eslint-disable-next-line
String.prototype.trimEllip = function (length) {
  return this.length > length ? this.substring(0, length) + "..." : this;
}