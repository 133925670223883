import React, { useState, useMemo, useEffect } from "react";
import { Sidebar } from "primereact/sidebar";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Column } from "primereact/column";
import { Button, Spinner } from "reactstrap";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { useGetSlaCustomerByUsernameQuery, useUpdateSlaCustomerByUsernameMutation } from "../service/reportApi";

const ReportRightSideBar = ({ visible, onHide }) => {
  const [globalFilter, setGlobalFilter] = useState("");
  const [updateSlaCustomerByUsername, {isLoading: isUpdateSlaLoading}] = useUpdateSlaCustomerByUsernameMutation();
  const { data: originalCustomersData, isLoading, isError } = useGetSlaCustomerByUsernameQuery();
  
  const [originalCustomers, setOriginalCustomers] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [invalidEntries, setInvalidEntries] = useState(false);

  useEffect(() => {
    if (originalCustomersData && !isLoading && !isError) {
      const formattedData = originalCustomersData.map((customer) => ({
        id: customer.customerId,
        customer: customer.customerName,
        threshold: customer.slaPercentage.toString(),
      }));
      setOriginalCustomers(formattedData);
      setCustomers(formattedData);
    }
  }, [originalCustomersData, isLoading, isError]);

  useEffect(() => {
    if (visible) {
      setGlobalFilter("");
      setCustomers(originalCustomers);
      setInvalidEntries(false);
    }
  }, [visible, originalCustomers]);

  const onThresholdChange = (options, value) => {
    if (value === '' || /^[0-9]*\.?[0-9]*$/.test(value)) {
      const floatValue = value === '' ? '' : parseFloat(value);
      if (value === '' || (floatValue >= 0 && floatValue <= 100)) {
        const updatedCustomers = customers.map((customer) => {
          if (customer.id === options.rowData.id) {
            return { ...customer, [options.field]: value };
          }
          return customer;
        });
        setCustomers(updatedCustomers);
        const hasInvalidEntries = updatedCustomers.some(customer => !validator({ rowData: customer, columnProps: { field: 'threshold' } }));
        setInvalidEntries(hasInvalidEntries);
      }
    }
  };

  const handleOnHide = () => {
    if (!changeThresholdExist(originalCustomers, customers)) {
      Swal.fire({
        title: "Are you sure?",
        text: "Your changes will not be saved",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, discard changes",
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire({
            title: "Threshold not updated",
            text: "No changes applied",
            icon: "error",
          });
          setGlobalFilter("");
          setCustomers(originalCustomers);
          onHide();
        }
      });
    } else {
      setGlobalFilter("");
      setCustomers(originalCustomers);
      onHide();
    }
  };

  const thresholdEditor = (options) => {
    return (
      <InputText
        type="text"
        value={options.rowData["threshold"]}
        onChange={(e) => onThresholdChange(options, e.target.value)}
        style={{ width: '100%' }}
      />
    );
  };

  const customGlobalFilter = (customer, filter) => {
    return customer.customer.toLowerCase().includes(filter.toLowerCase());
  };

  const filteredCustomers = useMemo(() => {
    return customers.filter((customer) =>
      customGlobalFilter(customer, globalFilter)
    );
  }, [customers, globalFilter]);

  const getModObj = (originalArray, modifiedArray) => {
    let modifiedObjects = [];

    originalArray.forEach((originalObj, index) => {
      const modifiedObj = modifiedArray[index];
      if (JSON.stringify(originalObj) !== JSON.stringify(modifiedObj)) {
        modifiedObjects.push(modifiedObj);
      }
    });

    return modifiedObjects;
  }

  const saveThreshold = () => {
    const modCustomer = getModObj(originalCustomers, customers);
    const body = modCustomer.map(cus => {
      return {
        customerId: cus.id,
        slaPercentage: parseFloat(cus.threshold),
      };
    });
    updateSlaCustomerByUsername(body)
      .unwrap()
      .then(() => {
        toast.success('Threshold updated.');
        setGlobalFilter("");
        setCustomers(originalCustomers);
        onHide();
      })
      .catch(() => {
        toast.error('Failed to update threshold');
        setGlobalFilter("");
        setCustomers(originalCustomers);
        onHide();
      });
  };

  const changeThresholdExist = (arr1, arr2) => {
    if (arr1.length !== arr2.length) return false;

    return arr1.every(obj1 => 
      arr2.some(obj2 => 
        JSON.stringify(obj1) === JSON.stringify(obj2)
      )
    );
  }

  const validator = (e) => {
    const { rowData, columnProps } = e;
    const { field } = columnProps || {};
    const value = rowData ? rowData[field] : '';
    // Check if value is empty or a valid float between 0 and 100
    return value && /^[0-9]*\.?[0-9]*$/.test(value) && parseFloat(value) > 0 && parseFloat(value) <= 100;
  }

  return (
    <Sidebar
      visible={visible}
      position="right"
      onHide={handleOnHide}
      blockScroll
    >
      <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
        <div style={{ flexGrow: 1 }}>
          <h2 style={{ fontWeight: 700 }} className="mb-1">
            Configure Threshold
          </h2>
          <h5 className="text-muted mb-2">The threshold applies to both Device Availability and Tunnel Availability reports.</h5>
          <span className="p-input-icon-left w-100">
            <i className="pi pi-search" />
            <InputText
              className="w-100"
              type="search"
              defaultValue={globalFilter}
              onInput={(e) => setGlobalFilter(e.target.value)}
              placeholder="Search Customer"
              size="sm"
            />
          </span>
          <div className="mt-2">
            <DataTable
              value={filteredCustomers}
              scrollable
              scrollHeight="65vh"
              editMode="cell"
              responsiveLayout="scroll"
              style={{ minHeight: "75vh" }}
              sortField="customer"
              sortOrder={1}
              rowHover
              loading={isLoading}
            >
              <Column field="customer" header="CUSTOMER" sortable />
              <Column
                field="threshold"
                header="AVAILABILITY THRESHOLD (%)"
                editor={thresholdEditor}
                editorValidator={validator}
              />
            </DataTable>
          </div>
        </div>
        <div style={{ textAlign: "center", marginTop: "15px" }}>
          <Button
            type="button"
            onClick={saveThreshold}
            className="btn btn-default px-5 py-1"
            disabled={invalidEntries || changeThresholdExist(originalCustomers, customers) || isUpdateSlaLoading}
          >
            Save
            {isUpdateSlaLoading && <Spinner className="ml-2" color="light" size="sm" />}
          </Button>
        </div>
      </div>
    </Sidebar>
  );
};

export default ReportRightSideBar;
