import { FieldArray } from 'formik';
import React from 'react';
import {
	Button,
	Col,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	Row,
} from 'reactstrap';
import classnames from 'classnames';
import {
	RowItem,
	CustomLabel,
	TritronikInputText,
	ButtonLinkIcon,
	TritronikSelect,
} from './../../../../components';
// import { useIsGatewayIdExistsMutation } from 'features/device/service/deviceApi';
import { useGatewayNodeRef } from '../../hooks/useGatewayNodeRef';

const ModalAddGateway = ({
	isOpen,
	toggle,
	validateForm,
	resetForm,
	errors,
}) => {
	// const [isGatewayIdExists] = useIsGatewayIdExistsMutation();
	const { gatewayNodeOptions } = useGatewayNodeRef();

	// const validateGatewayId = async (id, gatewayId) => {
	// 	let error;
	// 	if (gatewayId !== '') {
	// 		const exists = await isGatewayIdExists({ id, gatewayId }).unwrap();
	// 		if (exists) {
	// 			error = 'Gateway ID already exists.';
	// 		}
	// 	}
	// 	return error;
	// };

	const isValid = !errors['gateways'];

	const onApply = () => {
		validateForm().then(() => {
			if (isValid) {
				toggle();
			}
		});
	};

	const onCancel = () => {
		resetForm();
		toggle();
	};
	return (
		<Modal
			className='modal-dialog-centered'
			size='lg'
			style={{ maxWidth: '1100px', width: '100%' }}
			toggle={toggle}
			isOpen={isOpen}
			backdrop='static'
		>
			<ModalHeader>Gateway ID</ModalHeader>
			<FieldArray name='gateways'>
				{(arrHelper) => (
					<div>
						<ModalBody>
							{arrHelper.form.values.gateways.length === 0 && (
								<span className='text-danger'>
									Gateway ID is required at least one.
								</span>
							)}
							{arrHelper.form.values.gateways &&
								arrHelper.form.values.gateways.map((gw, index) => (
									<div key={`gateway-${index}`}>
										<div
											className={classnames({ 'border-top my-3': index > 0 })}
										/>
										<Row>
											<Col className='col-6 col-md-2'>
												<span>Gateway ID - {index + 1}</span>
											</Col>
											<Col>
												<Button
													color='danger'
													size='sm'
													onClick={() =>
														arrHelper.remove(index)
													} /* className={classnames({ 'd-none': index === 0 })} */
												>
													<i className='fa fa-trash'></i>
												</Button>
											</Col>
										</Row>
										<Row
											key={`gw-${index}`}
											className={classnames({ 'mt-3': index > 0 })}
										>
											<Col sm='12' lg='6'>
												<RowItem
													offset
													rightCol='col-12 col-lg-7'
													label={<CustomLabel name='Gateway ID' required />}
													value={
														<TritronikInputText
															small
															name={`gateways.${index}.gatewayId`}
															placeholder='Gateway ID'
															// validate={(val) =>
															// 	validateGatewayId(arrHelper.form.values.id, val)
															// }
														/>
													}
												/>
											</Col>
											<Col sm='12' lg='6'>
												<RowItem
													offset
													rightCol='col-12 col-lg-7'
													label={<CustomLabel name='Peer ID' />}
													value={
														<TritronikInputText
															small
															name={`gateways.${index}.peerId`}
															placeholder='Peer ID'
														/>
													}
												/>
											</Col>
											<Col sm='12' lg='6'>
												<RowItem
													offset
													rightCol='col-12 col-lg-7'
													label={<CustomLabel name='Peer IP' />}
													value={
														<TritronikInputText
															small
															name={`gateways.${index}.peerIp`}
															placeholder='Peer IP'
														/>
													}
												/>
											</Col>
											<Col sm='12' lg='6'>
												<RowItem
													offset
													rightCol='col-12 col-lg-7'
													label={<CustomLabel name='Gateway Node' />}
													value={
														<TritronikSelect
															name={`gateways.${index}.node`}
															value={arrHelper.form.values.gateways[index].node}
															onChange={(e) => arrHelper.form.setFieldValue(`gateways.${index}.node`, e.value)}
															placeholder='Gateway Node'
															options={gatewayNodeOptions}
														/>
													}
												/>
											</Col>
										</Row>
									</div>
								))}

							<div className='d-flex justify-content-end'>
								<ButtonLinkIcon
									type='button'
									label='Add Gateway ID'
									onClick={() =>
										arrHelper.push({
											gatewayId: '',
											peerId: '',
											peerIp: '',
											node: '',
										})
									}
								/>
							</div>
						</ModalBody>
						<ModalFooter className='d-flex align-items-center justify-content-end'>
							<Button
								type='button'
								color='secondary'
								className='px-4'
								onClick={onCancel}
							>
								Cancel
							</Button>
							<Button
								type='button'
								color='primary'
								className='px-4'
								onClick={onApply}
								disabled={
									!(
										arrHelper.form.values.gateways &&
										arrHelper.form.values.gateways.length > 0
									)
								}
							>
								Apply
							</Button>
						</ModalFooter>
					</div>
				)}
			</FieldArray>
		</Modal>
	);
};

export default ModalAddGateway;
