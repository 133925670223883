import React, { useMemo } from 'react';
import { Card, CardBody } from 'reactstrap';
import ReactECharts from 'echarts-for-react';
import _ from 'lodash';
import AggregateTable from '../AggregateTable';
import { useAggregate } from './../../hooks/useAggregate';

const DeviceSignalTrendEchart = ({ data, simCards }) => {
    const { calculateAggregate } = useAggregate();
    const { options, aggregated } = useMemo(() => {
        const series = [];
        const aggregated = [];

        const getSimCardByImsi = (imsi) => {
            return simCards && _.find(simCards, { 'imsi': imsi });
        }

        if (data && data.datasets && data.datasets.length > 0) {
            data.datasets.forEach(dt => {
                const dataSeries = [];
                const datas = dt.data;

                const agg = calculateAggregate(datas);

                if (agg !== null) {
                    aggregated.push({
                        name: dt.label,
                        min: `${agg.min} dBm`,
                        max: `${agg.max} dBm`,
                        avg: `${agg.avg} dBm`,
                    })
                }

                data.timestamps && data.timestamps.forEach((ts, i) => {
                    dataSeries.push([ts, dt.data[i] || null]);
                });

                const simCard = getSimCardByImsi(dt.label);

                series.push({
                    name: simCard ? `${dt.label} (SIM ${simCard.slotNumber})` : dt.label,
                    data: dataSeries,
                    type: 'line',
                    symbol: 'none',
                    smooth: false
                })
            });
        }

        const options = {
            title: {
                show: true
            },
            legend: {
                show: true
            },
            toolbox: {
                feature: {
                    dataZoom: {
                        yAxisIndex: 'none'
                    }
                }
            },
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'cross'
                }
            },
            dataZoom: [
                {
                    type: 'inside',
                },
                {
                    start: 0,
                    end: 100
                }
            ],
            visualMap: {
                top: 50,
                right: 100,
                pieces: [
                  {
                    gt: -65, // Greater than -65
                    color: '#93CE07', // Green for "EXCELLENT"
                    label: 'EXCELLENT'
                  },
                  {
                    gt: -75,
                    lte: -65, // Between -75 and -65
                    color: '#FBDB0F', // Yellow for "GOOD"
                    label: 'GOOD'
                  },
                  {
                    gt: -85,
                    lte: -75, // Between -85 and -75
                    color: '#FC7D02', // Orange for "FAIR"
                    label: 'FAIR'
                  },
                  {
                    gt: -95,
                    lte: -85, // Between -95 and -85
                    color: '#FD0100', // Red for "POOR"
                    label: 'POOR'
                  },
                  {
                    lte: -95, // Less than or equal to -95
                    color: '#999999', // Gray for "NO SIGNAL"
                    label: 'NO SIGNAL'
                  }
                ],
                outOfRange: {
                  color: '#999'
                }
              }
            ,
            xAxis: {
                type: 'time',
                boundaryGap: false,
                axisLabel: {
                    formatter: {
                        year: '{yyyy}',
                        month: '{MMM} {yyyy}',
                        day: '{d} {MMM}',
                        hour: '{HH}:{mm}',
                        minute: '{HH}:{mm}',
                        second: '{HH}:{mm}:{ss}',
                        millisecond: '{hh}:{mm}:{ss} {SSS}',
                        none: '{yyyy}-{MM}-{dd} {hh}:{mm}:{ss} {SSS}'
                    }
                }
            },
            yAxis: {
                type: 'value',
                axisLabel: {
                    formatter: '{value} dBm'
                },
                min: 'dataMin',
                max: 'dataMax'
            },
            grid: {
                left: '5%',
                right: '15%',
            },
            series: series
        }

        return { options, aggregated };

    }, [data, simCards, calculateAggregate]);

    return (
        <Card>
            <CardBody style={{ width: '100%', height: '100%' }}>
                <ReactECharts option={options} style={{ width: '100%', height: 400 }} />
                {aggregated && aggregated.length > 0 && <AggregateTable data={aggregated} columns={['IMSI', 'Min', 'Max', 'Avg']} />}
            </CardBody>
        </Card>
    )
}

export default DeviceSignalTrendEchart
