/*!

=========================================================
* Argon Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import AlertRoute from "./features/alert/AlertRoute";
import { UserActivityRoute } from "./features/user-activity";
import {
  VIEW_ALL_ALERT,
  VIEW_CUSTOMER,
  VIEW_DASHBOARD,
  VIEW_GROUP,
  VIEW_LIST_OF_DEVICE,
  VIEW_USER_LIST,
  VIEW_USAGE_REPORT,
  VIEW_USER_ACTIVITY_LOG,
  VIEW_REGION_LIST,
  VIEW_LIST_AREA,
  VIEW_DEVICE_INVENTORY_LIST,
  VIEW_SIMCARD_INVENTORY_LIST,
  VIEW_NETWORKS,
  GENERATE_API_TOKEN,
  VIEW_LICENSE,
  VIEW_OLT_ONT,
  VIEW_ROUTER,
  VIEW_ROLES,
  VIEW_PACKAGE_POLICY,
  VIEW_BANDWIDTH_POLICY,
  VIEW_ONU_ONT_LIST,
} from "./config";
import {
  Login,
  Dashboard,
  DeviceRoutes,
  GroupRouter,
  CustomerRouter,
  UserRouter,
  Report,
  Profile,
  ForgotPassword,
  ResetPassword,
  PolicyList,
  PackagePolicyList,
  RouterPolicy,
  RegisteredOnuList,
  PerformanceDashboard,
} from "./features";

import { NotificationRoute } from "./features/notification";
import { RegionRouter } from "./features/region";
import { AreaRouter } from "./features/area";
import DeviceInventoryRoutes from "./features/inventory/devices/routes";
import SimcardInventoryRoutes from "./features/inventory/simcards/routes";
import { ListNetworkPage } from "./features/network";
import { GenerateTokenPage } from "./features/token";
import OnuInventoryRoutes from "features/inventory/onu/routes";
import LicenseRouter from "features/license/routes";
import RouterListRouter from "features/router/routerList/RouterListRouter";
import oltRoute from "features/olt/oltRoute";
import { RoleRouter } from "features/role-management";
import InterfaceRouter from "features/sdwan/routes";

const routes = [
  {
    collapse: true,
    name: "Dashboard",
    icon: "mdi mdi-view-dashboard",
    state: "dashboardCollapse",
    private: true,
    featureNames: [VIEW_DASHBOARD],
    views: [
      {
        path: "/dashboard",
        name: "Device Dashboard",
        miniName: "D",
        component: Dashboard,
        private: true,
        featureName: VIEW_DASHBOARD,
        layout: "/admin",
      },
      {
        path: "/performance-dashboard",
        name: "Performance Dashboard",
        miniName: "PD",
        component: PerformanceDashboard,
        layout: "/admin",
        private: true,
        featureName: VIEW_DASHBOARD,
      },
    ],
  },
  {
    collapse: true,
    name: "Devices",
    icon: "mdi mdi-briefcase",
    state: "deviceCollapse",
    private: true,
    featureNames: [
      VIEW_LIST_OF_DEVICE,
      VIEW_CUSTOMER,
      VIEW_GROUP,
      VIEW_REGION_LIST,
      VIEW_LIST_AREA,
    ],
    views: [
      {
        path: "/devices",
        name: "All Device",
        miniName: "AD",
        component: DeviceRoutes,
        layout: "/admin",
        featureName: VIEW_LIST_OF_DEVICE,
        private: true,
      },
      {
        path: "/customers",
        name: "By Customer",
        miniName: "BC",
        component: CustomerRouter,
        layout: "/admin",
        featureName: VIEW_CUSTOMER,
        private: true,
      },
      {
        path: "/groups",
        name: "By Group",
        miniName: "BG",
        component: GroupRouter,
        layout: "/admin",
        featureName: VIEW_GROUP,
        private: true,
      },
      {
        collapse: true,
        name: "By Region",
        state: "regionCollapse",
        miniName: "BR",
        private: true,
        featureNames: [VIEW_REGION_LIST, VIEW_LIST_AREA],
        views: [
          {
            path: "/regions",
            name: "All Region",
            icon: "mdi mdi-web",
            component: RegionRouter,
            layout: "/admin",
            featureName: VIEW_REGION_LIST,
            private: true,
          },
          {
            path: "/areas/",
            name: "All Area",
            icon: "mdi mdi-map-marker",
            component: AreaRouter,
            layout: "/admin",
            featureName: VIEW_LIST_AREA,
            private: true,
          },
        ],
      },
    ],
  },
  {
    path: "/sdwan",
    name: "SD-WAN Devices",
    miniName: "SDW",
    layout: "/admin",
    icon: "mdi mdi-lan",
    component: InterfaceRouter,
    // private: true,
  },
  {
    collapse: true,
    name: "Inventory",
    icon: "mdi mdi-view-list",
    state: "inventoryCollapse",
    private: true,
    featureNames: [VIEW_DEVICE_INVENTORY_LIST, VIEW_SIMCARD_INVENTORY_LIST],
    views: [
      {
        path: "/inventory/device",
        name: "Device",
        miniName: "DI",
        layout: "/admin",
        component: DeviceInventoryRoutes,
        featureName: VIEW_DEVICE_INVENTORY_LIST,
        private: true,
      },
      {
        path: "/inventory/simcard",
        name: "SIM",
        miniName: "SIM",
        layout: "/admin",
        component: SimcardInventoryRoutes,
        featureName: VIEW_SIMCARD_INVENTORY_LIST,
        private: true,
      },
      {
        path: "/inventory/onu",
        name: "ONU/ ONT",
        miniName: "ONU",
        layout: "/admin",
        component: OnuInventoryRoutes,
        featureName: VIEW_ONU_ONT_LIST,
        private: true,
      },
    ],
  },
  {
    collapse: true,
    name: "Users",
    icon: "mdi mdi-account-box",
    state: "userCollapse",
    private: true,
    featureNames: [VIEW_USER_LIST, VIEW_ROLES],
    views: [
      {
        path: "/users",
        name: "All Users",
        miniName: "U",
        component: UserRouter,
        private: true,
        featureName: VIEW_USER_LIST,
        layout: "/admin",
      },
      {
        path: "/roles",
        name: "All Roles",
        miniName: "R",
        component: RoleRouter,
        layout: "/admin",
        private: true,
        featureName: VIEW_ROLES,
      },
    ],
  },
  {
    collapse: true,
    name: "OLT - ONT",
    icon: "mdi mdi-memory",
    state: "ont-OLTCollapse",
    private: true,
    featureNames: [VIEW_OLT_ONT],
    views: [
      {
        path: "/OLT",
        name: "OLT",
        miniName: "OLT",
        layout: "/admin",
        component: oltRoute,
        private: true,
        featureName: VIEW_OLT_ONT,
      },
      {
        path: "/ont",
        name: "ONT/ ONU",
        miniName: "ONU",
        layout: "/admin",
        component: RegisteredOnuList,
        private: true,
        featureName: VIEW_OLT_ONT,
      },
    ],
  },
  {
    collapse: true,
    name: "Policy",
    icon: "mdi mdi-note",
    state: "policyCollapse",
    private: true,
    featureNames: [VIEW_BANDWIDTH_POLICY, VIEW_PACKAGE_POLICY],
    views: [
      {
        path: "/bandwidthPolicy",
        name: "Bandwidth Policy",
        miniName: "BWP",
        layout: "/admin",
        component: PolicyList,
        private: true,
        featureName: VIEW_BANDWIDTH_POLICY,
      },
      {
        path: "/packagePolicy",
        name: "Package Policy",
        miniName: "PKG",
        layout: "/admin",
        component: PackagePolicyList,
        private: true,
        featureName: VIEW_PACKAGE_POLICY,
      },
    ],
  },
  {
    collapse: true,
    name: "Router",
    icon: "mdi mdi-router-wireless",
    state: "routerCollapse",
    private: true,
    featureNames: [VIEW_ROUTER],
    views: [
      {
        path: "/router-list",
        name: "All",
        miniName: "ALL",
        layout: "/admin",
        component: RouterListRouter,
        // featureName: VIEW_ALL_ALERT,
        // private: true,
      },
      {
        path: "/router-policy",
        name: "Policy",
        miniName: "POL",
        layout: "/admin",
        component: RouterPolicy,
      },
    ],
  },
  {
    path: "/license",
    name: "Subscription License",
    miniName: "SL",
    layout: "/admin",
    icon: "mdi mdi-book",
    component: LicenseRouter,
    private: true,
    featureName: VIEW_LICENSE,
  },
  {
    collapse: true,
    name: "Alert & Notification",
    icon: "mdi mdi-alert",
    state: "alertNotificationCollapse",
    private: true,
    featureNames: [VIEW_ALL_ALERT],
    views: [
      {
        path: "/alerts",
        name: "Alert",
        miniName: "ALR",
        layout: "/admin",
        component: AlertRoute,
        featureName: VIEW_ALL_ALERT,
        private: true,
      },
      {
        path: "/notifications",
        name: "Notification",
        miniName: "NTF",
        layout: "/admin",
        component: NotificationRoute,
      },
    ],
  },
  {
    path: "/reporting",
    name: "Report",
    icon: "mdi mdi-file-chart",
    component: Report,
    layout: "/admin",
    featureName: VIEW_USAGE_REPORT,
    private: true,
  },
  {
    path: "/user-activity",
    name: "User Activity Log",
    icon: "mdi mdi-gesture-double-tap",
    component: UserActivityRoute,
    layout: "/admin",
    featureName: VIEW_USER_ACTIVITY_LOG,
    private: true,
  },
  {
    path: "/network",
    name: "Network",
    icon: "mdi mdi-network",
    component: ListNetworkPage,
    layout: "/admin",
    featureName: VIEW_NETWORKS,
    private: true,
    beta: true,
  },
  {
    path: "/generate-token",
    name: "Generate Token",
    icon: "mdi mdi-api",
    component: GenerateTokenPage,
    layout: "/admin",
    featureName: GENERATE_API_TOKEN,
    private: true,
  },
  {
    path: "/user-profile",
    name: "Profile",
    icon: "mdi mdi-account",
    component: Profile,
    layout: "/admin",
    invisible: true,
    private: false,
  },
  {
    path: "/login",
    icon: "mdi mdi-account",
    name: "Login",
    component: Login,
    layout: "/auth",
    invisible: true,
  },
  {
    path: "/forgot-password",
    icon: "mdi mdi-account",
    name: "Forgot Password",
    component: ForgotPassword,
    layout: "/auth",
    invisible: true,
  },
  {
    path: "/reset-password",
    icon: "mdi mdi-account",
    name: "Reset Password",
    component: ResetPassword,
    layout: "/auth",
    invisible: true,
  },
];

export default routes;
