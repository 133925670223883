import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQuery } from './../../../services/baseApi';

export const reportApi = createApi({
  reducerPath: 'reportApi',
  baseQuery: baseQuery,
  tagTypes: ['Report', 'Template', 'SlaCustomer'],
  endpoints: (builder) => ({
    getUsageReportByUsername: builder.query({
      query: (params) => ({
        url: `/report/device-usage-report/username`,
        params
      })
    }),
    getSignalStrengthReportByUsername: builder.query({
      query: (params) => ({
        url: `/report/signal-strength-report/username`,
        params
      })
    }),
    getAvailabilityReportByUsername: builder.query({
      query: (params) => ({
        url: `/report/device-availability-report/username`,
        params
      })
    }),
    getDeviceAvailabilityReportByUsernameV2: builder.query({
      query: (params) => ({
        url: `/report/v2/device-availability-report/username`,
        params
      }),
      providesTags: ['Report']
    }),
    getTunnelAvailabilityReportByUsername: builder.query({
      query: (params) => ({
        url: `/report/device-tunnel-availability-report/username`,
        params
      })
    }),
    getTunnelAvailabilityReportByUsernameV2: builder.query({
      query: (params) => ({
        url: `/report/v2/device-tunnel-availability-report/username`,
        params
      })
    }),
    getDeviceIntegrationReportByUsername: builder.query({
      query: (params) => ({
        url: `/report/device-integration-report/username`,
        params
      })
    }),
    getReportFilterReferenceByUsername: builder.query({
      query: (username) => ({
        url: `/report/filter/references/username/${username}`
      })
    }),
    getReportDeviceIntegrationFilterReferencesByUsername: builder.query({
      query: (username) => ({
        url: `/report/filter/references/device-integration/username/${username}`
      })
    }),
    getReportTemplates: builder.query({
      query: (params) => ({
        url: `/report-template`,
        params
      }),
      providesTags: (result) =>
        result?.content
          ? [
            ...result.content.map(({ id }) => ({ type: 'Template', id })),
            { type: 'Template', id: 'LIST' },
          ]
          : [{ type: 'Template', id: 'LIST' }],
    }),
    createReportTemplate: builder.mutation({
      query: (body) => ({
        url: '/report-template',
        method: 'POST',
        body
      }),
      invalidatesTags: ['Template']
    }),
    updateReportTemplate: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `/report-template/${id}`,
        method: 'PUT',
        body
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'Template', id }]
    }),
    deleteReportTemplate: builder.mutation({
      query: (id) => ({
        url: `/report-template/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Template']
    }),
    getSlaCustomerByUsername: builder.query({
      query: (params) => ({
        url: `/report/sla-customer/username`,
        params
      }),
      providesTags: (result) => 
        result
          ? result.map(({ customerId }) => ({ type: 'SlaCustomer', id: customerId }))
          : [{ type: 'SlaCustomer', id: 'LIST' }],
    }),
    updateSlaCustomerByUsername: builder.mutation({
      query: (body) => ({
        url: `/report/sla-customer/username`,
        method: 'POST',
        body
      }),
      invalidatesTags: ['SlaCustomer', 'Report']
    }),
    getDeviceAvailabilityReportDailyByUsernameV2: builder.query({
      query: (params) => ({
        url: `/report/v2/device-availability-table/daily/username`,
        params
      }),
    }),
    getDeviceAvailabilityDetailPerTickByUsername: builder.query({
      query: (params) => ({
        url: `/report/v2/${params.reportType}-availability-detail-per-tick/username`,
        params
      }),
    }),
  })
});

export const {
  useGetUsageReportByUsernameQuery,
  useGetSignalStrengthReportByUsernameQuery,
  useGetAvailabilityReportByUsernameQuery,
  useGetDeviceAvailabilityReportByUsernameV2Query,
  useGetTunnelAvailabilityReportByUsernameQuery,
  useGetTunnelAvailabilityReportByUsernameV2Query,
  useGetDeviceIntegrationReportByUsernameQuery,
  useGetReportFilterReferenceByUsernameQuery,
  useGetReportDeviceIntegrationFilterReferencesByUsernameQuery,
  useGetReportTemplatesQuery,
  useCreateReportTemplateMutation,
  useUpdateReportTemplateMutation,
  useDeleteReportTemplateMutation,
  useGetSlaCustomerByUsernameQuery,
  useUpdateSlaCustomerByUsernameMutation,
  useGetDeviceAvailabilityReportDailyByUsernameV2Query,
  useGetDeviceAvailabilityDetailPerTickByUsernameQuery
} = reportApi;

