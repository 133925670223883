import { PageContent } from "components";
import React, { useMemo } from "react";
import PageToolbar from "../component/PageToolbar";
import { Card, CardBody } from "reactstrap";
import { useParams } from "react-router";

const FirewallAliasesList = () => {
  const { sn } = useParams();
  const title = useMemo(() => {
    if (!sn) return "Firewall Aliases";
    return `${sn}'s Firewall Aliases`;
  }, [sn]);
  return (
    <PageContent style={{ marginTop: "-14rem" }}>
      <PageToolbar title={title} />
      <Card className="tab-gateways">
        <CardBody className="p-3">Firewall Aliases</CardBody>
      </Card>
    </PageContent>
  );
};

export default FirewallAliasesList;
