import * as Yup from 'yup';
import _ from 'lodash';

Yup.addMethod(Yup.array, 'uniqueProperty', function(propertyPath, message) {
  return this.test('unique', '', function(list) {
      const errors = [];

      list && list.length > 0 && list.forEach((item, index) => {
          const propertyValue = _.get(item, propertyPath);

          if (propertyValue && _.filter(list, [propertyPath, propertyValue]).length > 1) {
              errors.push(
                  this.createError({
                      path: `${this.path}[${index}].${propertyPath}`,
                      message,
                  })
              );
          }
      });

      if (!_.isEmpty(errors)) {
          throw new Yup.ValidationError(errors);
      }

      return true;
  });
});

export const deviceProfileSchema = Yup.object().shape({
  macAddress: Yup.string(),
  sn: Yup.string().required('Serial number device required.'),
  type: Yup.string().required('Device type required.'),
  circuitNumber: Yup.string().required('Device ID required.'),
  ipAddress: Yup.string(),
  location: Yup.string().required('Address field required.'),
  username: Yup.string(),
  password: Yup.string(),
  confirmPassword: Yup.string().oneOf([Yup.ref('password'), null], 'Password must match!'),
  customerId: Yup.number().required('Customer required.'),
  regionId: Yup.number().required('Region must be set.'),
  areaId: Yup.number().required('Area must be set.'),
  gateways: Yup.array().of(Yup.object().shape({
    id: Yup.number(),
    gatewayId: Yup.string().required('Gateway ID required.'),
    peerId: Yup.string(),
    peedIp: Yup.string(),
  }))
  .min(1, 'Gateway must be set at least one.')
  .uniqueProperty('gatewayId', 'Gateway ID must be unique.')
});

export const simProfileSchema = Yup.object().shape({
  simCards: Yup.array().of(Yup.object().shape({
    imsi: Yup.string(),
    slotNumber: Yup.number().required('Slot Number required.'),
    gsmNumber: Yup.string().required('GSM Number required.'),
    circuitNumber: Yup.string().required('SIA required.'),
    imei: Yup.string(),
    operator: Yup.string(),
    ipAddress: Yup.string(),
    quotaPackage: Yup.string().required('Quota required.'),
    quotaPackageUom: Yup.string().required('Quota UOM required.'),
  }))
  .uniqueProperty('imsi', 'IMSI must be unique.')
  .uniqueProperty('gsmNumber', 'GSM Number must be unique.')
  .uniqueProperty('circuitNumber', 'SIA must be unique.')
});

export const updateDeviceConfigurationSchema = Yup.object().shape({
  areaId: Yup.number().required('Area must be set.'),
  simCards: Yup.array().of(Yup.object().shape({
    slotNumber: Yup.number().required('Slot Number required.'),
    gsmNumber: Yup.string().required('GSM Number required.'),
    circuitNumber: Yup.string().required('SIA required.'),
    quotaPackage: Yup.string().required('Quota required.'),
    quotaPackageUom: Yup.string().required('Quota UOM required.'),
  }))
  .uniqueProperty('imsi', 'IMSI must be unique.')
  .uniqueProperty('gsmNumber', 'GSM Number must be unique.')
  .uniqueProperty('circuitNumber', 'SIA must be unique.')

})

export const updateDeviceOverviewSchema = Yup.object().shape({
  sn: Yup.string().required('Serial number device required.'),
  type: Yup.string().required('Device type required.'),
  circuitNumber: Yup.string().required('Device ID required.'),
  location: Yup.string().required('Address field required.'),
  username: Yup.string(),
  password: Yup.string(),
  customerId: Yup.number(),
  regionId: Yup.number().required('Region must be set.'),
  areaId: Yup.number().required('Area must be set.'),
  gateways: Yup.array().of(Yup.object().shape({
    id: Yup.number(),
    gatewayId: Yup.string().required('Gateway ID required.'),
    peerId: Yup.string(),
    peedIp: Yup.string(),
  }))
  // .uniqueProperty('gatewayId', 'Gateway ID must be unique.')
  .required()
  .min(1, 'Gateway must be set at least one.')
});


export const deviceValidationSteps = [deviceProfileSchema, simProfileSchema];
