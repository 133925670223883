import classnames from "classnames";
import { FieldArray, Form, Formik } from "formik";
import _ from "lodash";
import React, { useCallback, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { Badge, Button, Col, Row, Spinner } from "reactstrap";
import Swal from "sweetalert2";
import { onKeyDown, unixToDate, unknown } from "utils";
import {
  ButtonLinkIcon,
  CustomLabel,
  Gap,
  PrimeDropdown,
  RowItem,
  TritronikInputText,
  TritronikMultiSelect,
  TritronikSelect,
} from "../../../../components";
import { ModalAddArea } from "../../../area/components";
import {
  ADD_NEW_AREA,
  ADD_NEW_GROUP,
  CHANGE_SIM_STATUS,
  CONFIGURE_DEVICE,
  CONFIG_DEVICE_GROUP,
  CONFIG_PRIMARY_SIM,
  CREATE_CUSTOMER,
  UPDATE_DEVICE_AREA,
  UPDATE_DEVICE_CUSTOMER,
} from "./../../../../config";
import { Can } from "./../../../../config/Can";
import { ModalAddGroup } from "./../../../../features/group";
import {
  useRemoveDeviceAreaMutation,
  useSetDeviceAreaMutation,
  useSetPrimarySimCardMutation,
  useSetQuotaLimitSimCardMutation,
  useUpdateDeviceMutation,
  useUpdateSimCardMutation,
  useRebootDeviceMutation,
  useEnableTunnelDeviceMutation,
  useDisableTunnelDeviceMutation,
  useUpdateFirmwareMutation,
  useSetSimToDeviceMutation,
  useIsSimcardCircuitNumberExistMutation,
} from "./../../../device/service/deviceApi";
import { ModalAddCustomer } from "./../../../customers/components";
import DeviceRemoteConfiguration from "./DeviceRemoteConfiguration";
import { useFirmware } from "features/device/hooks/useFirmware";
import { billingCycleOptions } from "./../../../../utils";
import ModalChangeSIM from "../ModalChangeSIM";
import { useUpdateSimcardMutation as useUpdateSimcardInventoryMutation } from "./../../../inventory/simcards/services/simcardInventoryApi";
import { updateDeviceConfigurationSchema } from "./../../validationSchema";
import { useCan } from "hooks/useCan";
import classNames from "classnames";
import moment from "moment";

export function DeviceConfiguration({
  device,
  groupOptions,
  areaOptions,
  customerOptions,
  uomOptions,
  quotaOptions,
  serviceTypeOptions,
  serviceApplicationOptions,
  unprovisionGsmNumbersOptions,
  refetch,
}) {
  const {
    simCards,
    primarySimCardSlotNumber,
    groupIds,
    area,
    customerId,
    sn,
    type,
    id,
    connectionServiceStatus,
    firmwareVersion,
  } = device;
  const [loading, setLoading] = useState(false);
  const [modalGroup, setModalGroup] = useState(false);
  const [modalAddArea, setModalAddArea] = useState(false);
  const [modalAddCustomer, setModalAddCustomer] = useState(false);
  const [modalChangeSim, setModalChangeSim] = useState(false);
  const [changeSimSelected, setChangeSimSelected] = useState(null);

  const hasPermission = useCan();

  const canConfigure = hasPermission(CONFIGURE_DEVICE);

  const [updateDevice] = useUpdateDeviceMutation();
  const [setDeviceToArea] = useSetDeviceAreaMutation();
  const [removeDeviceFromArea] = useRemoveDeviceAreaMutation();
  const [setPrimarySimCard] = useSetPrimarySimCardMutation();
  const [setSimCardQuotaLimit, { isLoading: loadingSetQuotaLimit }] =
    useSetQuotaLimitSimCardMutation();
  const [updateSimCard] = useUpdateSimCardMutation();
  const [changeDeviceSim, { isLoading: loadingChangeSim }] =
    useSetSimToDeviceMutation();

  const [checkCircuitNumber] = useIsSimcardCircuitNumberExistMutation();

  const [rebootDevice] = useRebootDeviceMutation();

  const { updateAvailable, firmwareInfo } = useFirmware(id);
  const [disableTunnelDevice] = useDisableTunnelDeviceMutation();
  const [enableTunnelDevice] = useEnableTunnelDeviceMutation();
  const [updateFirmware] = useUpdateFirmwareMutation();

  const [updateSimInventory] = useUpdateSimcardInventoryMutation();

  const simCardsOptions = useMemo(() => {
    const opts = [];
    if (simCards && simCards.length > 0) {
      simCards.forEach((sc) => {
        opts.push({ value: sc.slotNumber, label: `SIM ${sc.slotNumber}` });
      });
    } else {
      opts.push({ value: "", label: "No SIM attached" });
    }
    return opts;
  }, [simCards]);

  const handlePrimarySimCardChanges = (e, setFieldValue) => {
    setFieldValue("primarySim", e.value);
  };

  const handleCancelButton = (resetForm) => {
    resetForm();
  };

  const handleSelectedGroups = (e, setFieldValue) => {
    setFieldValue("groupIds", e.value);
  };

  const onSubmit = async (values, { resetForm }) => {
    Swal.fire({
      icon: "question",
      title: "Are you sure want to update this?",
      text: `Device: ${sn}, Type: ${type}.`,
      showLoaderOnConfirm: true,
      showCancelButton: true,
      confirmButtonText: "Yes",
      reverseButtons: true,
      preConfirm: async () => {
        setLoading(true);

        try {
          const { primarySim, groupIds, customerId, simCards, areaId } = values;
          await updateDevice({
            id: device.id,
            body: {
              groupIds: groupIds,
              customerId: customerId,
            },
            params: {
              updateGroup: true,
              updateCustomer: true,
            },
          }).unwrap();

          if (areaId !== "") {
            await setDeviceToArea({ id: device.id, areaId: areaId }).unwrap();
          } else {
            // area not set and current area is exists, then unset from area
            if (device.area !== null) {
              await removeDeviceFromArea({
                id: device.id,
                areaId: device.area.id,
              }).unwrap();
            }
          }

          if (simCards && simCards.length > 0) {
            if (primarySim !== "" && primarySim !== null) {
              await setPrimarySimCard({
                id: device.id,
                slotNumber: primarySim,
              }).unwrap();
            }

            await simCards.forEach(async (simCard) => {
              const {
                billingCycle,
                ratePlan,
                slotNumber,
                services,
                quotaPackage,
                quotaPackageUom,
                status,
                gsmNumber,
                circuitNumber,
                servicesApplication,
              } = simCard;

              await setSimCardQuotaLimit({
                id: device.id,
                slotNumber: slotNumber,
                limit: quotaPackage,
                uom: quotaPackageUom,
              }).unwrap();

              await updateSimCard({
                deviceId: device.id,
                id: simCard.id,
                status,
                services,
                ratePlan,
                billingCycle,
                gsmNumber,
                circuitNumber,
                servicesApplication,
                primary: parseInt(slotNumber) === parseInt(primarySim),
              });
            });
          }
          resetForm();
          toast.success("Device configuration updated.");
          return true;
        } catch {
          setLoading(false);
          return false;
        } finally {
          setLoading(false);
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    });
  };

  const handleSelectArea = (e, setFieldValue) => {
    setFieldValue("areaId", e.value);
  };

  const handleSelectCustomer = (e, setFieldValue) => {
    setFieldValue("customerId", e.value);
  };

  const renderLabelWithDefault = (label, defaultValue) => {
    return (
      <span>
        {label}
        {defaultValue && (
          <Badge className="ml-2" color="primary">
            Default
          </Badge>
        )}
      </span>
    );
  };

  const validateCircuitNumber = async (circuitNumber, id) => {
    let error;

    if (circuitNumber !== "") {
      const exists = await checkCircuitNumber({ id, circuitNumber }).unwrap();
      if (exists) {
        error = "SIA Number already exists.";
      }
    }
    return error;
  };

  const onRebootDevice = useCallback(() => {
    Swal.fire({
      icon: "question",
      title: "Are you sure want to reboot this device?",
      text: `Device: ${sn}, Type: ${type}.`,
      showLoaderOnConfirm: true,
      showCancelButton: true,
      confirmButtonText: "Yes",
      reverseButtons: true,
      preConfirm: async () => {
        try {
          await rebootDevice(id).unwrap();
          refetch();
          toast.success("Reboot device command sent.");
          return true;
        } catch {
          return false;
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    });
  }, [id, sn, type, rebootDevice, refetch]);

  const serviceStatus = connectionServiceStatus === "DISABLED" ? false : true;

  const onToggleDeviceStatus = useCallback(() => {
    Swal.fire({
      icon: "question",
      title: `Are you sure want to ${
        serviceStatus ? "disable" : "enable"
      } this Device Tunnel?`,
      text: `Device: ${sn}, Type: ${type}.`,
      showLoaderOnConfirm: true,
      showCancelButton: true,
      confirmButtonText: "Yes",
      reverseButtons: true,
      preConfirm: async () => {
        try {
          if (serviceStatus) {
            await disableTunnelDevice(id).unwrap();
            toast.success("Device Tunnel Disabled.");
          } else {
            await enableTunnelDevice(id).unwrap();
            toast.success("Device Tunnel Enabled.");
          }
          refetch();
          return true;
        } catch {
          return false;
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    });
  }, [
    id,
    sn,
    type,
    serviceStatus,
    enableTunnelDevice,
    disableTunnelDevice,
    refetch,
  ]);

  const onUpdateFirmware = useCallback(() => {
    Swal.fire({
      icon: "question",
      title: `Are you sure to update device firmware?`,
      text: `Device: ${sn}, Type: ${type}.`,
      showLoaderOnConfirm: true,
      showCancelButton: true,
      confirmButtonText: "Yes",
      reverseButtons: true,
      preConfirm: async () => {
        try {
          await updateFirmware(id).unwrap();
          toast.success("Update firmware executed.");
          refetch();
          return true;
        } catch {
          return false;
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    });
  }, [id, sn, type, updateFirmware, refetch]);

  const onChangeSimcardClick = (simCard) => {
    setChangeSimSelected(simCard);
    setModalChangeSim(true);
  };

  const onSubmitChangeSim = async (values, { resetForm }) => {
    Swal.fire({
      icon: "question",
      title: "Are you sure want to change sim for this?",
      text: `Device: ${sn}, Type: ${type}, SLOT: ${values.slotNumber}.`,
      showLoaderOnConfirm: true,
      showCancelButton: true,
      confirmButtonText: "Yes",
      reverseButtons: true,
      preConfirm: async () => {
        const {
          slotNumber,
          id: simCardId,
          quotaPackage,
          quotaPackageUom,
          primary,
          circuitNumber,
        } = values;
        try {
          await changeDeviceSim({
            simCardId,
            deviceId: device.id,
            slotNumber,
            primary: primary,
          }).unwrap();

          await updateSimInventory({
            id: simCardId,
            params: {
              updatePrimary: true,
              updateQuotaLimit: true,
            },
            circuitNumber,
            quotaLimitUom: quotaPackageUom,
            quotaLimitValue: quotaPackage,
            primary: true,
          }).unwrap();

          resetForm();
          setModalChangeSim(!modalChangeSim);
          return true;
        } catch (error) {
          Swal.showValidationMessage(`Request failed: ${error.message}`);
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    });
  };

  const formatQuotaDate = (timestamp) => {
    const momentDate = moment(timestamp);
    const today = moment();
    const isToday = momentDate.isSame(today, 'day');
  
    if (isToday) {
      return `Today, ${momentDate.format('HH:mm:ss')}`;
    } else {
      return momentDate.format('DD MMMM YYYY, HH:mm:ss');
    }
  }

  return (
    <React.Fragment>
      <Formik
        enableReinitialize={true}
        initialValues={{
          primarySim:
            primarySimCardSlotNumber !== null ? primarySimCardSlotNumber : "",
          groupIds: groupIds || [],
          areaId: (area && area.id) || "",
          customerId: customerId,
          simCards: _.sortBy(simCards, [(o) => o.slotNumber]).map((sim) => {
            const {
              quotaLimitInHuman,
              billingCycle,
              ratePlan,
              services,
              gsmNumber,
              circuitNumber,
              servicesApplication,
              ...restSimCard
            } = sim;
            const arrPackage =
              quotaLimitInHuman && quotaLimitInHuman.split(" ");
            let quotaPackage = "";
            let quotaPackageUom = "";
            if (arrPackage && arrPackage.length === 2) {
              quotaPackage = arrPackage[0];
              quotaPackageUom = arrPackage[1];
            }
            return {
              ...restSimCard,
              billingCycle: billingCycle || "",
              ratePlan: ratePlan || "",
              services: services || "",
              servicesApplication: servicesApplication || "",
              quotaPackage: quotaPackage || "",
              quotaPackageUom: quotaPackageUom || "",
              gsmNumber: gsmNumber || "",
              circuitNumber: circuitNumber || "",
            };
          }),
        }}
        validateOnChange={false}
        validateOnBlur={true}
        onSubmit={onSubmit}
        validationSchema={updateDeviceConfigurationSchema}
      >
        {({
          values,
          handleSubmit,
          setFieldValue,
          resetForm,
          dirty,
          errors,
        }) => (
          <Form onSubmit={handleSubmit} onKeyDown={onKeyDown}>
            <Row
              className={classNames("mt-4 mb-5", { "d-none": !canConfigure })}
            >
              <Col className="text-right">
                {dirty && (
                  <Button
                    className="btn btn-outline-default px-5 py-1 ml-auto"
                    onClick={() => handleCancelButton(resetForm)}
                  >
                    Cancel
                  </Button>
                )}
                <Button
                  className="btn btn-default px-5 py-1"
                  type="submit"
                  disabled={loading || !dirty || !canConfigure}
                >
                  Apply
                  {loading && (
                    <Spinner className="ml-2" color="light" size="sm" />
                  )}
                </Button>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col md="6" sm="12" className="px-5 border-right">
                <Row>
                  <Col>
                    <h2 className="text-dark ml--3">Device Setting</h2>
                    <Gap height={10} />
                    <RowItem
                      stripped
                      label="Group"
                      value={
                        <Can I="do" this={CONFIG_DEVICE_GROUP} passThrough>
                          {(allowed) => (
                            <TritronikMultiSelect
                              filter
                              name="groupIds"
                              options={groupOptions}
                              onChange={(e) =>
                                handleSelectedGroups(e, setFieldValue)
                              }
                              disabled={!allowed}
                            />
                          )}
                        </Can>
                      }
                    />
                    <RowItem
                      label={
                        <>
                          <Can I="do" this={ADD_NEW_GROUP} passThrough>
                            {(allowed) => (
                              <ButtonLinkIcon
                                label="Add New Group"
                                onClick={() => setModalGroup(!modalGroup)}
                                disabled={!allowed}
                                style={{ marginLeft: -20 }}
                              />
                            )}
                          </Can>
                          <ModalAddGroup
                            isOpen={modalGroup}
                            toggle={() => setModalGroup(!modalGroup)}
                          />
                        </>
                      }
                    />
                  </Col>
                </Row>
                <Gap height={10} />
                <Row>
                  <Col>
                    <h2 className="text-dark ml--3">Area Setting</h2>
                    <Gap height={10} />
                    <RowItem
                      stripped
                      label={<CustomLabel name="Area" required />}
                      value={
                        <Can I="do" this={UPDATE_DEVICE_AREA} passThrough>
                          {(allowed) => (
                            <div className="w-100">
                              <PrimeDropdown
                                filter
                                onChange={(e) =>
                                  handleSelectArea(e, setFieldValue)
                                }
                                value={values.areaId}
                                options={[
                                  { label: "Select Area", value: "" },
                                  ...areaOptions,
                                ]}
                                disabled={!allowed}
                                className={errors.areaId ? "p-invalid" : ""}
                              />
                              {errors.areaId && (
                                <span className="p-error">{errors.areaId}</span>
                              )}
                            </div>
                          )}
                        </Can>
                      }
                    />
                    <RowItem
                      label={
                        <>
                          <Can I="do" this={ADD_NEW_AREA} passThrough>
                            {(allowed) => (
                              <ButtonLinkIcon
                                label="Add New Area"
                                onClick={() => setModalAddArea(!modalAddArea)}
                                disabled={!allowed}
                                style={{ marginLeft: -20 }}
                              />
                            )}
                          </Can>
                          <ModalAddArea
                            isOpen={modalAddArea}
                            toggle={() => setModalAddArea(!modalAddArea)}
                          />
                        </>
                      }
                    />
                  </Col>
                </Row>
              </Col>
              <Col md="6" sm="12" className="px-5 border-left">
                <div hidden>
                  <Row>
                    <Col>
                      <h2 className="text-dark ml--3">SIM Setting</h2>
                      <Gap height={10} />
                      <RowItem
                        stripped
                        label="Primary SIM"
                        value={
                          <Can I="do" this={CONFIG_PRIMARY_SIM} passThrough>
                            {(allowed) => (
                              <TritronikSelect
                                onChange={(e) =>
                                  handlePrimarySimCardChanges(e, setFieldValue)
                                }
                                value={values.primarySim}
                                options={simCardsOptions}
                                disabled={!allowed}
                              />
                            )}
                          </Can>
                        }
                      />
                    </Col>
                  </Row>
                </div>
                <Row>
                  <Col>
                    <h2 className="text-dark ml--3">Customer Setting</h2>
                    <Gap height={10} />
                    <RowItem
                      stripped
                      label="Customer"
                      value={
                        <Can I="do" this={UPDATE_DEVICE_CUSTOMER} passThrough>
                          {(allowed) => (
                            <PrimeDropdown
                              filter
                              onChange={(e) =>
                                handleSelectCustomer(e, setFieldValue)
                              }
                              value={values.customerId}
                              options={[
                                { label: "Select Customer", value: "" },
                                ...customerOptions,
                              ]}
                              disabled={!allowed}
                            />
                          )}
                        </Can>
                      }
                    />
                    <RowItem
                      label={
                        <>
                          <Can I="do" this={CREATE_CUSTOMER} passThrough>
                            {(allowed) => (
                              <ButtonLinkIcon
                                label="Add New Customer"
                                onClick={() =>
                                  setModalAddCustomer(!modalAddCustomer)
                                }
                                disabled={!allowed}
                                style={{ marginLeft: -20 }}
                              />
                            )}
                          </Can>
                          <ModalAddCustomer
                            isOpen={modalAddCustomer}
                            toggle={() =>
                              setModalAddCustomer(!modalAddCustomer)
                            }
                          />
                        </>
                      }
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <hr />
            <DeviceRemoteConfiguration
              device={device}
              onRebootDevice={onRebootDevice}
              deviceStatus={serviceStatus}
              onToggleDeviceStatus={onToggleDeviceStatus}
              onUpdateFirmware={onUpdateFirmware}
              updateAvailable={updateAvailable}
              firmwareInfo={firmwareInfo}
              firmwareVersion={firmwareVersion}
              refetch={refetch}
            />
            <hr />
            <Row className="mt-4">
              <FieldArray name="simCards">
                {(arrHelper) => {
                  return (
                    values.simCards &&
                    values.simCards.map((simCard, index) => (
                      <Col
                        key={`sim-${index}`}
                        className={classnames("col-6 px-5 pb-5", {
                          "border-right": index % 2 === 0,
                          "border-left": index % 2 !== 0,
                        })}
                      >
                        <Row>
                          <Col>
                            <div className="d-flex justify-content-end align-items-center">
                              <h2 className="text-dark ml--3">
                                Device SIM {simCard?.slotNumber}
                              </h2>
                              <Button
                                id={`change-sim-${index}`}
                                type="button"
                                className="btn btn-danger px-5 py-1 ml-auto"
                                onClick={() => onChangeSimcardClick(simCard)}
                                disabled={!canConfigure}
                              >
                                Change SIM
                              </Button>
                            </div>
                            <Gap height={15} />
                            <RowItem
                              stripped
                              label="SIM Status"
                              value={
                                <Can
                                  I="do"
                                  this={CHANGE_SIM_STATUS}
                                  passThrough
                                >
                                  {(allowed) => (
                                    <label className="custom-toggle">
                                      <input
                                        type="checkbox"
                                        id={`simCards[${index}].status`}
                                        name={`simCards[${index}].status`}
                                        checked={
                                          values.simCards[index].status ===
                                          "Activated"
                                        }
                                        value={values.simCards[index].status}
                                        onChange={(e) => {
                                          arrHelper.form.setFieldValue(
                                            `simCards[${index}].status`,
                                            e.target.checked
                                              ? "Activated"
                                              : "Deactivated"
                                          );
                                        }}
                                        disabled={!allowed}
                                      />
                                      <span className="custom-toggle-slider rounded-circle" />
                                    </label>
                                  )}
                                </Can>
                              }
                            />
                            <RowItem
                              label="IMEI"
                              value={simCard?.imei || unknown}
                            />
                            <RowItem
                              stripped
                              label="IMSI"
                              value={
                                <div>
                                  <div>{simCard?.imsi || unknown}</div>
                                  {errors?.simCards?.[index]?.imsi && (
                                    <div className="invalid-feedback d-block">
                                      {errors?.simCards?.[index]?.imsi}
                                    </div>
                                  )}
                                </div>
                              }
                            />
                            <RowItem
                              label="SIA"
                              value={
                                <TritronikInputText
                                  small
                                  name={`simCards[${index}].circuitNumber`}
                                  placeholder="SIA"
                                  validate={(val) =>
                                    validateCircuitNumber(val, simCard.id)
                                  }
                                  disabled={!canConfigure}
                                />
                              }
                            />
                            <RowItem
                              stripped
                              label="GSM Number"
                              value={
                                <div>
                                  <div>{simCard?.gsmNumber || unknown}</div>
                                  {errors?.simCards?.[index]?.gsmNumber && (
                                    <div className="invalid-feedback d-block">
                                      {errors?.simCards?.[index]?.gsmNumber}
                                    </div>
                                  )}
                                </div>
                              }
                            />
                            <RowItem
                              label="Activation Date"
                              value={
                                simCard.connectedTime
                                  ? unixToDate(simCard.connectedTime)
                                  : unknown
                              }
                            />
                            <RowItem
                              stripped
                              label="Connection Status"
                              value={
                                <span
                                  className={
                                    simCard?.connectionStatus === "Disconnected"
                                      ? "text-danger"
                                      : "text-success"
                                  }
                                >
                                  {simCard?.connectionStatus || unknown}
                                </span>
                              }
                            />
                            <RowItem
                              label="Operator"
                              value={_.upperFirst(simCard?.operator) || unknown}
                            />
                            <RowItem
                              stripped
                              label={renderLabelWithDefault(
                                "Quota",
                                simCard?.defaultValue
                              )}
                              value={
                                <div className="d-flex w-100 justfiy-content-between">
                                  <TritronikInputText
                                    small
                                    name={`simCards[${index}].quotaPackage`}
                                    placeholder="Quota"
                                    readOnly={!canConfigure}
                                  />
                                  <Gap width={20} />
                                  <TritronikSelect
                                    name={`simCards[${index}].quotaPackageUom`}
                                    value={
                                      values.simCards[index].quotaPackageUom
                                    }
                                    onChange={(e) =>
                                      arrHelper.form.setFieldValue(
                                        `simCards[${index}].quotaPackageUom`,
                                        e.value
                                      )
                                    }
                                    options={uomOptions}
                                    placeholder="UOM"
                                    disabled={!canConfigure}
                                  />
                                </div>
                              }
                            />
                            <RowItem label="Remaining Quota"/>
                            <RowItem
                              label="Measured by Modem"
                              value={`${simCard?.remainingQuotaInHuman || 0} (${
                                simCard?.remainingQuotaInPercentage
                              } %)`}
                              offset
                              onlyLabelOffset
                            />
                            <RowItem
                              label="Provider Data"
                              value={
                                <>
                                  <div>{simCard?.providerRemainingQuotaInHuman ? `${simCard.providerRemainingQuotaInHuman}  (${simCard.providerRemainingQuotaInPercentage}  %)` : 'No Data Received'}</div>
                                  <div className="text-muted">{simCard?.providerRemainingQuotaLastUpdated ? `Last updated at ${formatQuotaDate(simCard.providerRemainingQuotaLastUpdated)}` : 'Last updated at -'}</div>
                                </> 
                              }
                              offset
                              onlyLabelOffset
                            />
                            <RowItem
                              stripped
                              label="Over Quota Usage"
                              value={`${simCard?.overQuotaInHuman || 0} (${
                                simCard?.overQuotaInPercentage
                              } %)`}
                            />
                            <RowItem
                              label="Billing Status"
                              value={simCard?.billingStatus || unknown}
                            />
                            <RowItem
                              stripped
                              label={renderLabelWithDefault(
                                "Billing Cycle",
                                simCard?.defaultValue
                              )}
                              value={
                                <TritronikSelect
                                  name={`simCards[${index}].billingCycle`}
                                  placeholder="Billing Cycle"
                                  options={billingCycleOptions()}
                                  value={values.simCards[index].billingCycle}
                                  onChange={(e) =>
                                    arrHelper.form.setFieldValue(
                                      `simCards[${index}].billingCycle`,
                                      e.value
                                    )
                                  }
                                  disabled={!canConfigure}
                                />
                              }
                            />
                            <RowItem
                              label={renderLabelWithDefault(
                                "Rate Plan",
                                simCard?.defaultValue
                              )}
                              value={
                                <TritronikInputText
                                  small
                                  name={`simCards[${index}].ratePlan`}
                                  placeholder="Rate Plan"
                                  readOnly={!canConfigure}
                                />
                              }
                            />
                            <RowItem
                              stripped
                              label={renderLabelWithDefault(
                                "Service",
                                simCard?.defaultValue
                              )}
                              value={
                                <TritronikSelect
                                  editable
                                  name={`simCards[${index}].services`}
                                  placeholder="Service Type"
                                  options={serviceTypeOptions}
                                  value={values.simCards[index].services}
                                  onChange={(e) =>
                                    arrHelper.form.setFieldValue(
                                      `simCards[${index}].services`,
                                      e.value
                                    )
                                  }
                                  disabled={!canConfigure}
                                />
                              }
                            />
                            <RowItem
                              label={renderLabelWithDefault(
                                "Service Application",
                                simCard?.defaultValue
                              )}
                              value={
                                <TritronikSelect
                                  editable
                                  name={`simCards[${index}].servicesApplication`}
                                  placeholder="Service Application"
                                  options={serviceApplicationOptions}
                                  value={
                                    values.simCards[index].servicesApplication
                                  }
                                  onChange={(e) =>
                                    arrHelper.form.setFieldValue(
                                      `simCards[${index}].servicesApplication`,
                                      e.value
                                    )
                                  }
                                  disabled={!canConfigure}
                                />
                              }
                            />
                          </Col>
                        </Row>
                      </Col>
                    ))
                  );
                }}
              </FieldArray>
            </Row>
            {errors.simCards && (
              <Row>
                <Col className="px-5">
                  <div className="alert alert-danger bg-danger">
                    <p>
                      There are required fields that is not provisioned
                      correctly. Make sure you have provision device & simcard
                      correctly.
                    </p>
                    <ul>
                      {errors.simCards &&
                        errors.simCards.map((error, i) => {
                          if (!error) return null;
                          if (_.isObject(error)) {
                            return (
                              <li>
                                Sim Card {i + 1}
                                <ul>
                                  {Object.entries(error).map(([k, e]) => (
                                    <li>{e}</li>
                                  ))}
                                </ul>
                              </li>
                            );
                          }
                          return null;
                        })}
                    </ul>
                  </div>
                </Col>
              </Row>
            )}
            <Row
              className={classNames("mt-4 mb-5", { "d-none": !canConfigure })}
            >
              <Col className="text-right">
                {dirty && (
                  <Button
                    className="btn btn-outline-default px-5 py-1 ml-auto"
                    onClick={() => handleCancelButton(resetForm)}
                  >
                    Cancel
                  </Button>
                )}
                <Button
                  className="btn btn-default px-5 py-1"
                  type="submit"
                  disabled={loading || !dirty || !canConfigure}
                >
                  Apply
                  {loading && (
                    <Spinner className="ml-2" color="light" size="sm" />
                  )}
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
      <ModalChangeSIM
        isOpen={modalChangeSim}
        toggle={() => setModalChangeSim(!modalChangeSim)}
        sim={changeSimSelected}
        device={device}
        unprovisionGsmNumbersOptions={unprovisionGsmNumbersOptions}
        quotaOptions={quotaOptions}
        uomOptions={uomOptions}
        checkCircuitNumber={checkCircuitNumber}
        onSubmit={onSubmitChangeSim}
        isLoading={loadingChangeSim || loadingSetQuotaLimit}
      />
    </React.Fragment>
  );
}
