import EChartsReact from "echarts-for-react";
import moment from "moment";
import React, { useCallback, useMemo, useState } from "react";
import { formatNumber } from "utils";
import DownDevicesDetailModal from "./DownDevicesDetailModal";

const ReportEChart = ({
  data = [],
  isLoading = false,
  unit = "",
  minInterval = false,
  dateRange,
  reportType = 'sla',
  filters
}) => {
  const [selectedTimestamp, setSelectedTimestamp] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const countDateRange = (dateRanges, range) => {
    const startTime = new Date(dateRanges.startTime);
    const endTime = new Date(dateRanges.endTime);

    const timeDifference = endTime - startTime;
    const daysDifference = timeDifference / (1000 * 60 * 60 * 24);
    return daysDifference < range;
  };

  const getTimestamp = (timestamp) => {
    const timeOfDay = timestamp.time.split(":");

    const startDate = new Date(timestamp.dateRange.startTime);
    startDate.setHours(timeOfDay[0], timeOfDay[1], 0, 0);

    return startDate.getTime();
  };

  const onClickChart = useCallback(
    (params) => {
      if (countDateRange(dateRange, 1) && params.seriesName === "Down Devices") {
        const timestamp = getTimestamp({ time: params.data.name, dateRange });
        setSelectedTimestamp(timestamp);
        setIsModalOpen(true);
      }
    },
    [dateRange]
  );

  const onEvents = useMemo(
    () => ({
      click: onClickChart,
    }),
    [onClickChart]
  );

  const options = useMemo(() => {
    let dataSeries = data && data.datasets ? data.datasets : [];
    const labels = data && data.labels ? data.labels : [];
    const isDaily = countDateRange(dateRange, 1);

    if (!dataSeries[0]?.data || dataSeries[0].data.length === 0) {
      
      return {
        title: {
          text: 'No Data Available',
          left: 'center',
          top: 'middle',
          textStyle: {
            color: '#888',
            fontSize: 14,
            fontWeight: 'normal'
          },
        },
        xAxis: {
          show: false,
        },
        yAxis: {
          show: false,
        },
        series: [],
      };
    }
  
    return {
      legend: {
        bottom: 0,
        type: "scroll",
      },
      tooltip: {
        trigger: "axis",
        formatter: function (params) {
          const tooltipTime = isDaily
            ? moment(params[0].axisValue).format("HH:mm")
            : moment(params[0].axisValue).format("DD MMM YYYY");
  
          let tooltipText = `${tooltipTime}<br/>`;
          params.forEach((item) => {
            tooltipText += `${item.marker} ${item.seriesName}: ${formatNumber(item.value[1])} ${item.seriesName === 'Average Availability' ? '%' : ""}<br/>`;
          });
          return tooltipText;
        },
      },
      grid: {
        left: 70,
        right: isDaily ? 70 : 40,
        top: 10,
        bottom: 70,
      },
      yAxis: [
        {
          type: "value",
          axisLabel: {
            formatter: `{value} ${unit}`,
          },
          axisLine: {
            show: true,
          },
          minInterval: 1,
          name: isDaily ? "Number of Down Devices" : "Number of Devices",
          nameLocation: "center",
          nameGap: 40,
        },
        {
          type: "value",
          axisLabel: {
            formatter: `{value}%`,
          },
          axisLine: {
            show: isDaily,
          },
          minInterval: 1,
          name: isDaily ? "Availability (%)" : "",
          nameLocation: "center",
          nameGap: 50,
          max: 100,
          splitLine: {
            show: false
          }
        },
      ],
      xAxis: [
        {
          type: "time",
          axisLabel: {
            hideOverlap: true,
            align: "center",
            formatter: isDaily
              ? (value) => moment(value).format("HH:mm")
              : {},
          },
          splitLine: {
            show: true,
          },
          name: isDaily ? "Time" : "Date",
          nameLocation: "center",
          nameGap: 30,
          axisTick: {
            alignWithLabel: true,
          },
          minInterval: 3600 * 1000, // 1 hour minimum interval for ticks
          maxInterval: isDaily ? 3600 * 1000 : "auto", // 1 hour minimum interval for ticks
          tickInterval: 3600 * 1000, // 1 hour for ticks
        },
      ],
      series: dataSeries.map((dataset, id) => {
        let color
        let symbol
        let name
        if (dataset.label === "DOWN") {
          color = "#F5365C";
          symbol = "emptyCircle"
          name = "Down Devices"
        } else if (dataset.label === "AVAILABILITY") {
          color = "#2DCE89";
          symbol = "none" 
          name = "Average Availability"
        } else if (dataset.label === "achieved") {
          color = "#6ace61";
          symbol = "circle"
          name = "Achieved"
        } else {
          color = "#f5365c";
          symbol = "circle"
          name = "Not Achieved"
        }
  
        return {
          type: "line",
          name: name,
          label: {
            show: false,
            fontSize: "11px",
          },
          lineStyle: isDaily
            ? {}
            : {
                color: color,
                width: 3,
              },
          // Set the correct yAxisIndex for each series
          yAxisIndex: dataset.label === "AVAILABILITY" ? 1 : 0,
          data: dataset.data.map((d, i) => {
            const label = moment(labels[i]);
            return {
              name: isDaily ? label.format("HH:mm") : label.format("DD-MM-YYYY"),
              value: [label.toISOString(), d], // Use ISO format for time axis
            };
          }),
          symbol: symbol,
          itemStyle: {
            color: color,
          },
          silent: dataset.label === "DOWN" ? false : true
        };
      }),
    };
  }, [data, dateRange, unit]);
  
  
  return (
    <>
      <EChartsReact
        notMerge={true}
        option={options}
        showLoading={isLoading}
        style={{ height: "100%" }}
        onEvents={onEvents}
      />
      {isModalOpen && (
        <DownDevicesDetailModal
          isModalOpen={isModalOpen}
          toggleModal={toggleModal}
          timestamp={selectedTimestamp}
          timestamps={data.timestamps}
          reportType={reportType}
          filters={filters}
        />
      )}
    </>
  );
};

export default ReportEChart;
